import AC from '../assets/pictos/AC.svg?react'
import AV from '../assets/pictos/AV.svg?react'
import BQ from '../assets/pictos/BQ.svg?react'
import CAS from '../assets/pictos/CAS.svg?react'
import CATA from '../assets/pictos/CATA.svg?react'
import CINE from '../assets/pictos/CINE.svg?react'
import ENL from '../assets/pictos/ENL.svg?react'
import FW from '../assets/pictos/FW.svg?react'
import MET from '../assets/pictos/MET.svg?react'
import MS from '../assets/pictos/MS.svg?react'
import MSEC from '../assets/pictos/MSEC.svg?react'
import TT from '../assets/pictos/TT.svg?react'
import THEGAME from '../assets/pictos/THEGAME.svg?react'

export const PictoByThemeId = {
  1: BQ,
  2: CAS,
  3: ENL,
  4: MET,
  5: CATA,
  6: AC,
  7: AV,
  8: MS,
  9: TT,
  10: CINE,
  11: FW,
  12: MSEC,
  100: THEGAME,
}

export const getPictoByThemeId = (themeId) => {
  const picto = PictoByThemeId[themeId]
  if (picto) {
    return picto
  } else {
    return () => <div>?</div>
  }
}
