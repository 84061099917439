import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useDrawerStore = create(
  immer((set) => ({
    showDrawer: false,
    setShowDrawer: (next) =>
      set((state) => {
        state.showDrawer = next
      }),
    drawerDatas: [],
    addData: (data) =>
      set((state) => {
        state.drawerDatas.push(data)
      }),
    clearData: () =>
      set((state) => {
        state.drawerDatas = []
      }),
  }))
)
