import dayjs from 'dayjs'
import { sortSessions } from '../../../utils/utils'

export const populateWeek = (week, users, rooms) => {
  if (Array.isArray(users)) users = users.reduce((acc, user) => ({ ...acc, [user._id]: user }), {})
  if (Array.isArray(rooms)) rooms = rooms.reduce((acc, room) => ({ ...acc, [room._id]: room }), {})
  week.users_week.forEach((uw) => {
    if (typeof uw.user === 'string') uw.user = users[uw.user]
  })
  week.users_week = week.users_week.filter((uw) => uw.user != undefined)

  week.days.forEach((d) => populateDay(d, users, rooms))

  // Populate dayIndex for additional time modal
  week.days.forEach((d) =>
    d.additionalTimes.forEach((at) => {
      at.user = users[at.user]
      at.dayIndex = dayjs(d.date).day() === 0 ? 6 : dayjs(d.date).day() - 1
    })
  )
  // Set x,y coordinates on session and intervals !
  week.days
    .filter((d) => !d.isReal)
    .forEach((d, dayIndex) => {
      const nbIntervals = d.intervals.length

      d.intervals.sort((a, b) => new Date(a.start) - new Date(b.start))
      d.intervals.forEach((i, intervalIndex) => {
        const nbSessions = i.sessions.length / 2 // Divisé par 2 car 2 lignes d'affichage (place_id 1 et 2)
        const sessionPlace1 = sortSessions(i.sessions.filter((s) => s?.place_id === 1))
        const sessionPlace2 = sortSessions(i.sessions.filter((s) => s?.place_id === 2))
        sessionPlace1.forEach((s, sessionIndex) => {
          // Si changement du nombre de digit (9->10 ou 99->100) entre position x (ou y) la selection... BUG !
          // donc on commence l'indexation des positions à 100
          s.x = sessionIndex + dayIndex * nbSessions + 100
          s.y = intervalIndex + 100
        })
        sessionPlace2.forEach((s, sessionIndex) => {
          // Si changement du nombre de digit (9->10 ou 99->100) entre position x (ou y) la selection... BUG !
          // donc on commence l'indexation des positions à 100
          s.x = sessionIndex + dayIndex * nbSessions + 100
          s.y = nbIntervals + intervalIndex + 100
        })
      })
    })
  // Do it all again for REAL !
  week.days
    .filter((d) => d.isReal)
    .forEach((d, dayIndex) => {
      const nbIntervals = d.intervals.length

      d.intervals.sort((a, b) => new Date(a.start) - new Date(b.start))
      d.intervals.forEach((i, intervalIndex) => {
        const nbSessions = i.sessions.length / 2 // Divisé par 2 car 2 lignes d'affichage (place_id 1 et 2)
        const sessionPlace1 = sortSessions(i.sessions.filter((s) => s?.place_id === 1))
        const sessionPlace2 = sortSessions(i.sessions.filter((s) => s?.place_id === 2))
        sessionPlace1.forEach((s, sessionIndex) => {
          // Si changement du nombre de digit entre position x (ou y) la selection... BUG !
          // donc on commence l'indexation des positions à 100
          s.x = sessionIndex + dayIndex * nbSessions + 100
          s.y = intervalIndex + 100
        })
        sessionPlace2.forEach((s, sessionIndex) => {
          // Si changement du nombre de digit entre position x (ou y) la selection... BUG !
          // donc on commence l'indexation des positions à 100
          s.x = sessionIndex + dayIndex * nbSessions + 100
          s.y = nbIntervals + intervalIndex + 100
        })
      })
    })
}

export const populateDay = (day, users, rooms) => {
  day.intervals
    .map((i) => i.sessions)
    .flat()
    .forEach((s) => {
      if (typeof s.room === 'string') s.room = rooms[s.room]
    })

  day.intervals
    .map((i) => i.users_interval)
    .flat()
    .forEach((ui) => {
      if (typeof ui.user === 'string') ui.user = users[ui.user]
    })
}

export const unpopulateDay = (day) => {
  day.intervals
    .map((i) => i.sessions)
    .flat()
    .forEach((s) => {
      s.room = s?.room?._id
    })
  day.intervals
    .map((i) => i.users_interval)
    .flat()
    .forEach((ui) => {
      ui.user = ui?.user?._id
    })
  day.intervals.forEach(
    (i) => (i.users_interval = i.users_interval.filter((ui) => ui.user != undefined))
  )
}

export const unpopulateWeek = (week) => {
  week.users_week.forEach((uw) => {
    uw.user = uw?.user?._id
  })
  week.days.forEach(unpopulateDay)
  week.days.forEach((d) =>
    d.additionalTimes.forEach((at) => {
      at.user = at?.user?._id
      delete at.dayIndex
    })
  )
}

export const cleanWeek = (week) => {
  week.users_week = week.users_week.map((uw) => {
    return {
      contract: uw.contract,
      days: uw.days,
      times: uw.times,
      user: uw.user,
      state: uw.state,
    }
  })
  week.days.forEach((d) => {
    delete d.n
    delete d.nombre_de_matin_a_bloquer
    delete d.week
    d.intervals.forEach((i) => {
      delete i.n
      delete i.day
      delete i.week
      i.users_interval.forEach((ui) => {
        delete ui.interval
      })
      i.sessions.forEach((s) => {
        delete s.interval
        delete s.day
        delete s.week
      })
    })
  })
}
