import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'
import { FORMAT_DD_MM_YYYY } from '../utils/constantes.js'
import dayjs from '../utils/dayjsHelper.js'
import { getMondayOfSameWeek } from '../utils/utils.js'

// Get the current date in the url
const initSearchParams = new URLSearchParams(window.location.search)
const dateParam = initSearchParams.get('date')
const formattedDate = dayjs(dateParam, FORMAT_DD_MM_YYYY)
const initDateDayJs = formattedDate.isValid() ? formattedDate : dayjs()

export const useDateStore = create(
  immer((set) => ({
    date: initDateDayJs,
    monday: getMondayOfSameWeek(initDateDayJs),

    setDate: (nextDate) =>
      set((state) => {
        state.date = nextDate
        state.monday = getMondayOfSameWeek(nextDate)
      }),
  }))
)

export const DateStoreWrapper = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { date } = useDateStore(
    useShallow((state) => ({
      date: state.date,
    }))
  )

  // Reflect the date change into the url
  useEffect(() => {
    const dateInSearchString = searchParams.get('date')
    const currentDate = dayjs(date).format(FORMAT_DD_MM_YYYY)

    if (currentDate !== dateInSearchString) {
      const dayjsDate = dayjs(date)
      if (dayjsDate.isValid()) {
        searchParams.set('date', dayjsDate.format(FORMAT_DD_MM_YYYY))
      } else {
        searchParams.set('date', dayjs().format(FORMAT_DD_MM_YYYY))
      }
      setSearchParams(searchParams, { replace: true })
    }
  }, [date, searchParams, setSearchParams])

  return children
}
