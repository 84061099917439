import './UserHead.css'

const UserHead = ({ user }) => {
  return (
    <div className="userHead" title={`${user?.first_name} ${user?.name}`}>
      <img
        src={
          user.photo
            ? `https://stockmanager.thegame-france.com/public/image/upload/user/${user.photo}`
            : 'https://stockmanager.thegame-france.com/public/image/upload/user/default.png'
        }
        alt={`user_head_${user?.initials}`}
      />
    </div>
  )
}
export default UserHead
