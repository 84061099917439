import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { clearTokens } from '../services/TokenService'
import { getUser } from '../services/AuthentificationService'
import { useSocketStore } from './SocketStore'

export const useAuthentificationStore = create(
  immer((set, get) => ({
    user: null,
    setUser: (nextUser) =>
      set((state) => {
        state.user = nextUser
      }),
    isLoading: true,
    setIsLoading: (nextLoading) =>
      set((state) => {
        state.isLoading = nextLoading
      }),
    refreshUser: () =>
      set(async (state) => {
        if (state.user && state.user?._id) {
          const result = await getUser(state.user?._id)
          if (result?.status === 200) {
            state.user = result?.data
          }
        }
      }),
    signOut: () => {
      clearTokens()
      set({ user: null })
      const socket = useSocketStore.getState().socket
      const setSocket = useSocketStore.getState().setSocket
      if (socket) {
        socket.disconnect()
        setSocket(null)
      }
    },
    hasPerm: (perm) => {
      const user = get().user
      let permTab = perm
      if (typeof perm === 'string') {
        permTab = [perm]
      }
      return user?.permissions?.some((p) => permTab.includes(p.key))
    },
  }))
)
