import AccountTreeIcon from '@mui/icons-material/AccountTree'
import DateRangeIcon from '@mui/icons-material/DateRange'
import EventIcon from '@mui/icons-material/Event'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { PERM_LOG, PERM_PLANNING_EDIT, PERM_PLANNING_INDISPO } from '../utils/permissions'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'

export const ROUTE_LOGIN = '/login'

export const ROUTE_DAY_VIEW_HEADLESS =
  '/Cy6rqPnVEV4HbschzefGjPT0tNJ6MHsGvPIS4Va2kV+AI24hEJPBXClZ9+tEwBXr+TRc5NVpforZI0JzujUAfzef'

export const ROUTE_GENERATEUR = '/generateur'
export const ROUTE_INDISPO = '/indispo'
export const ROUTE_DAY_VIEW = '/jour'
export const ROUTE_WEEK_VIEW = '/semaine'
export const ROUTE_SIGN_OUT = '/sign-out'
export const ROUTE_INDISPO_ASK = '/demandes-indispo'
export const ROUTE_LOGS = '/logs'
export const ROUTE_INDISPO_MANAGEMENT = '/gestion-indispo'

export const ALL_PAGES = [
  {
    title: 'Jour',
    perms: [],
    path: ROUTE_DAY_VIEW,
    icon: <EventIcon />,
  },
  {
    title: 'Semaine',
    perms: [],
    path: ROUTE_WEEK_VIEW,
    icon: <DateRangeIcon />,
  },
  {
    title: 'Générateur',
    perms: [PERM_PLANNING_EDIT],
    path: ROUTE_GENERATEUR,
    icon: <AccountTreeIcon />,
  },
  {
    title: 'Indispo',
    perms: [PERM_PLANNING_INDISPO],
    path: ROUTE_INDISPO,
    icon: <EventBusyIcon />,
  },
  {
    title: 'Demande indispo',
    perms: [],
    path: ROUTE_INDISPO_ASK,
    icon: <QuestionAnswerIcon />,
  },
  {
    title: 'Logs',
    perms: [PERM_LOG],
    path: ROUTE_LOGS,
    icon: <MonitorHeartIcon />,
  },
]
