import {
  KEY_ACCESS_TOKEN,
  isProduction,
  KEY_MIDLIFE_TOKEN_DATE,
  KEY_REFRESH_TOKEN,
  KEY_USER_ID,
} from '../utils/constantes'

export const saveTokens = (tokenObj) => {
  const { expires_in, access_token, refresh_token } = tokenObj
  if (expires_in) {
    const now = new Date()
    // Calculate the date (in ms) when the token will be half lifetime
    const midLifeDate = now.getTime() + (expires_in * 1000) / 2
    localStorage.setItem(KEY_MIDLIFE_TOKEN_DATE, midLifeDate.toString())
  }

  localStorage.setItem(KEY_ACCESS_TOKEN, access_token)
  localStorage.setItem(KEY_REFRESH_TOKEN, refresh_token)
}

export const getAccessToken = () => {
  return localStorage.getItem(KEY_ACCESS_TOKEN)
}

export const getRefreshToken = () => {
  return localStorage.getItem(KEY_REFRESH_TOKEN)
}

export const getLocalUserId = () => {
  return localStorage.getItem(KEY_USER_ID)
}

export const clearTokens = () => {
  localStorage.removeItem(KEY_ACCESS_TOKEN)
  localStorage.removeItem(KEY_MIDLIFE_TOKEN_DATE)
  localStorage.removeItem(KEY_REFRESH_TOKEN)
  localStorage.removeItem(KEY_USER_ID)
}

export const isTokenMidLifetimePassed = () => {
  const now = new Date()
  const midLifeDate = localStorage.getItem(KEY_MIDLIFE_TOKEN_DATE)
  if (midLifeDate) {
    const midLifeInS = (parseInt(midLifeDate) - now.getTime()) / 1000
    const isMidLifePassed = now.getTime() >= parseInt(midLifeDate)
    if (isMidLifePassed) {
      !isProduction && console.log(`Token midlife : ${midLifeInS}s >> Let's refresh it...`)
    }
    return isMidLifePassed
  } else {
    return false
  }
}
