import axiosInstance from './AxiosWrapper'
import { ENDPOINT_LOGIN, ENDPOINT_USER } from './Endpoints'

export const logIn = async (login, password) => {
  return await axiosInstance.post(ENDPOINT_LOGIN, { login, password })
}

export const getUser = async (id) => {
  return await axiosInstance.get(ENDPOINT_USER, { params: { id } })
}
