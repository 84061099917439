import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { useAuthentificationStore } from '../contexts/AuthenticationStore'

const SignOutRoute = () => {
  const navigate = useNavigate()
  const { signOut } = useAuthentificationStore(
    useShallow((state) => ({
      signOut: state.signOut,
    }))
  )

  useEffect(() => {
    signOut()
    navigate('/')
  }, [navigate, signOut])

  return null
}

export default SignOutRoute
