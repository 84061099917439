import axiosInstance from './AxiosWrapper'
import { ENDPOINT_REFRESH_TOKENS } from './Endpoints'
import { getLocalUserId, getRefreshToken } from './TokenService'

export const refreshTokens = async () => {
  const refreshToken = getRefreshToken()
  const idUser = getLocalUserId()
  return await axiosInstance.post(ENDPOINT_REFRESH_TOKENS, {
    refreshToken,
    id: idUser,
  })
}
