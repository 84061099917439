import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { useIsMouseClicking } from '../utils/customHooks'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useLocation } from 'react-router-dom'
import { ROUTE_INDISPO } from '../router/Routes'
import { INDISPO_AVAILABLE } from '../utils/constantes'

export const usePaintStore = create(
  immer((set) => ({
    currentBrush: null,
    isRightClicking: false,
    isLeftClicking: false,
    setIsRightClicking: (nextIsClicking) =>
      set((state) => {
        state.isRightClicking = nextIsClicking
      }),
    setIsLeftClicking: (nextIsClicking) =>
      set((state) => {
        state.isLeftClicking = nextIsClicking
      }),
    updateBrush: (nextBrush) =>
      set((state) => {
        state.currentBrush = nextBrush
      }),
  }))
)

export const PaintLogic = ({ canEdit }) => {
  const { isRightClicking, isLeftClicking } = useIsMouseClicking()
  const { setIsLeftClicking, setIsRightClicking, updateBrush } = usePaintStore(
    useShallow((state) => ({
      setIsRightClicking: state.setIsRightClicking,
      setIsLeftClicking: state.setIsLeftClicking,
      updateBrush: state.updateBrush,
    }))
  )
  const { pathname } = useLocation()

  // Listen to the RIGHT mouse click globally and store its state
  useEffect(() => {
    if (canEdit) {
      setIsRightClicking(isRightClicking)
    }
  }, [isRightClicking, setIsRightClicking, canEdit])

  // Toggle back to default the current brush when the pathname changes
  useEffect(() => {
    if (pathname === ROUTE_INDISPO) {
      updateBrush(INDISPO_AVAILABLE)
    } else {
      updateBrush(null)
    }
  }, [pathname, updateBrush])

  // Listen to the LEFT mouse click globally and store its state
  useEffect(() => {
    if (canEdit) {
      setIsLeftClicking(isLeftClicking)
    }
  }, [isLeftClicking, setIsLeftClicking, canEdit])

  return null
}
