import MenuIcon from '@mui/icons-material/Menu'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { useAuthentificationStore } from '../contexts/AuthenticationStore'
import { ALL_PAGES, ROUTE_SIGN_OUT } from './Routes'
import { useThemeContext } from '../contexts/ThemeProvider'
import { getPictoByThemeId } from '../utils/pictos'
import UserHead from '../components/ChatIndispo/UserHead/UserHead'

const AppBar = () => {
  const navigate = useNavigate()
  const { preferDark, toggleDarkTheme } = useThemeContext()
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const { user, hasPerm } = useAuthentificationStore(
    useShallow((state) => ({
      user: state.user,
      signOut: state.signOut,
      hasPerm: state.hasPerm,
    }))
  )

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const onNavigate = (path) => {
    setAnchorElNav(null)
    navigate(path)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const onDisconnect = () => {
    navigate(ROUTE_SIGN_OUT)
  }

  const onToggleTheme = () => {
    toggleDarkTheme()
    setAnchorElUser(null)
  }

  const location = useLocation()
  const currentPage = useMemo(() => {
    const pathTab = location?.pathname?.split('/')
    return ALL_PAGES.find((p) => p.path === `/${pathTab[pathTab.length - 1]}`)
  }, [location])

  const pagesICanAccess = ALL_PAGES.filter((p) => p.perms.every((r) => hasPerm(r)))

  const Logo = getPictoByThemeId(100)
  return (
    <MuiAppBar color="transparent" position="static" className="appbar hideOnCapture">
      <Toolbar>
        <Logo className={`logoTheGame hideOnMobile ${preferDark ? 'inverted' : ''}`} />
        <Typography
          variant="h6"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontWeight: 700,
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          Planning
        </Typography>

        {/* MENU on small screen */}
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pagesICanAccess.map((page) => (
              <MenuItem
                variant="outlined"
                color="secondary"
                key={page.path}
                onClick={() => onNavigate(page.path)}
              >
                {page.icon && <ListItemIcon>{page?.icon}</ListItemIcon>}
                <Typography textAlign="center">{page.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Typography
          variant="h5"
          sx={{
            mr: 2,
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
            fontWeight: 700,
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          {currentPage?.title}
        </Typography>

        {/* MENU Bandeau en taille d'écran à partir de md */}
        <div className="allLinks">
          {pagesICanAccess.map((page) => (
            <Link
              className={`linkAppBar ${currentPage?.path === page.path ? 'active' : ''}`}
              key={page.path}
              to={{ pathname: page.path, search: location.search }}
              onClick={() => navigate(page.path)}
            >
              <div className="linkIcon">{page.icon ? page.icon : <></>}</div>
              <div>{page.title}</div>
            </Link>
          ))}
        </div>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Menu" onClick={handleOpenUserMenu}>
            <div className="userHeadAppBar">
              <span>{user?.initials}</span>
              <UserHead user={user} />
            </div>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={onToggleTheme}>
              <ListItemIcon>
                {preferDark ? (
                  <Brightness7Icon className="toggleThemeSvg" />
                ) : (
                  <Brightness4Icon className="toggleThemeSvg" />
                )}
              </ListItemIcon>
              <ListItemText>{preferDark ? 'Thème clair' : 'Thème sombre'}</ListItemText>
            </MenuItem>
            <MenuItem onClick={onDisconnect}>
              <ListItemIcon>
                <PowerSettingsNewIcon color="error" fontSize="small" />
              </ListItemIcon>
              <ListItemText>Déconnexion</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </MuiAppBar>
  )
}
export default AppBar
