import { useCallback, useEffect } from 'react'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'
import { UNDO_KEY, useKeyPress } from '../utils/customHooks'
import { isInRange, setClipboard } from '../utils/utils'
import { useUsersStore } from './UsersStore'
import { useWeekStore } from './WeekStore'
import { useAdditionalTimeStore } from './AdditionalTimeStore'
import { useDayCommentStore } from './DayCommentStore'
import { useDrawerStore } from './DrawerStore'
import { toast } from 'react-toastify'
import { usePaintStore } from './PaintStore'

export const useSelectionStore = create(
  immer((set) => ({
    // Flag to know where the selection is occuring : Prev or Real
    isSelectingReal: false,
    changeIsSelectingReal: (nextIsSelecting) =>
      set((state) => {
        state.isSelectingReal = nextIsSelecting
      }),

    // Flag updated when the user handle the mouse click down
    isSelecting: false,
    changeIsSelecting: (nextIsSelecting) =>
      set((state) => {
        state.isSelecting = nextIsSelecting
      }),

    // Which session is currently being hovered
    sessionHovered: null,
    setSessionHovered: (session) =>
      set((state) => {
        state.sessionHovered = session
      }),

    // Object reflecting which sessions are selected (session _id as key and a Boolean as value)
    selectedSessions: {},
    updateSelectedSessions: (nextSelectedSessions) =>
      set((state) => {
        state.selectedSessions = nextSelectedSessions
      }),

    // The session where the selection has started
    startSelectingPos: null,
    setStartSelectingPos: (nextStartPos) =>
      set((state) => {
        state.startSelectingPos = nextStartPos
      }),
  }))
)

export const SelectionLogic = ({ canEdit }) => {
  const {
    isSelecting,
    startSelectingPos,
    setStartSelectingPos,
    sessionHovered,
    updateSelectedSessions,
    selectedSessions,
    isSelectingReal,
    isMultiSelection,
  } = useSelectionStore(
    useShallow((state) => ({
      isSelecting: state.isSelecting,
      startSelectingPos: state.startSelectingPos,
      setStartSelectingPos: state.setStartSelectingPos,
      sessionHovered: state.sessionHovered,
      updateSelectedSessions: state.updateSelectedSessions,
      selectedSessions: state.selectedSessions,
      isSelectingReal: state.isSelectingReal,
      isMultiSelection: Object.values(state.selectedSessions).filter((v) => v).length > 1,
    }))
  )
  const { week, undo } = useWeekStore(
    useShallow((state) => ({
      week: state.week,
      undo: state.undo,
    }))
  )
  const users = useUsersStore(useShallow((state) => state.users))
  const additionalTimeUser = useAdditionalTimeStore(useShallow((state) => state.additionalTimeUser))
  const showDrawer = useDrawerStore(useShallow((state) => state.showDrawer))
  const dayCommentId = useDayCommentStore(useShallow((state) => state.dayCommentId))
  const isLeftClicking = usePaintStore(useShallow((state) => state.isLeftClicking))

  // Prevent excel keyPress when modal is opened
  const hasModalOpened = additionalTimeUser !== null || dayCommentId !== null

  // Get the selected sessions and store the array of mg into the clipboard
  const onCopy = useCallback(async () => {
    const selectedIds = Object.keys(selectedSessions)
    if (showDrawer || selectedIds.length <= 1 || !canEdit) {
      return
    }

    const allCopiedSessions = (week?.days || [])
      .flatMap((day) => day.intervals)
      .flatMap((interval) => interval.sessions)
      .filter((session) => selectedIds.includes(session._id))

    let copycontent = ''
    const sortedCopied = allCopiedSessions.toSorted((s1, s2) => {
      if (s1.y === s2.y) {
        return s1.x - s2.x
      } else {
        return s1.y - s2.y
      }
    })

    let currentX
    let currentY

    for (let i = 0; i < sortedCopied.length; i++) {
      const session = sortedCopied[i]
      if (currentY && session.y !== currentY) {
        copycontent += '\n'
      } else if (currentX && session.x !== currentX) {
        copycontent += '\t'
      }
      currentX = session.x
      currentY = session.y

      if (session?.mg && users[session?.mg]) {
        copycontent += users[session?.mg]?.initials
      }
    }

    await setClipboard(copycontent)

    updateSelectedSessions({})
    setStartSelectingPos(null)
    toast.success('Données copiées dans le presse-papier ! ')
  }, [
    showDrawer,
    selectedSessions,
    week?.days,
    updateSelectedSessions,
    setStartSelectingPos,
    users,
    canEdit,
  ])

  // Copy event
  useEffect(() => {
    document.addEventListener('copy', onCopy)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('copy', onCopy)
    }
  }, [onCopy])

  // Navigation excel-like
  const onExcelKeyPress = useCallback(
    (event) => {
      if (event.key == 'Escape') {
        updateSelectedSessions({})
        setStartSelectingPos(null)
        event.preventDefault()
        return
      }
      if (startSelectingPos !== null && !isMultiSelection) {
        event.preventDefault()
        // Clean selection on 'Escape'
        let searchedX = startSelectingPos.x
        let searchedY = startSelectingPos.y
        switch (event.key) {
          case 'ArrowRight':
          case 'Tab':
            searchedX++
            break
          case 'ArrowLeft':
            searchedX--
            break
          case 'ArrowUp':
            searchedY--
            break
          case 'ArrowDown':
          case 'Enter':
          case 'NumpadEnter':
            searchedY++
            break
        }
        const allSessions = (week?.days || [])
          .filter((d) => d.isReal === isSelectingReal)
          .flatMap((day) => day.intervals)
          .flatMap((interval) => interval.sessions)
        const found = allSessions.find((s) => s?.x === searchedX && s?.y === searchedY)
        if (found) {
          updateSelectedSessions({ [found?._id]: true })
          setStartSelectingPos(found)
        }
      }
    },
    [
      startSelectingPos,
      updateSelectedSessions,
      isMultiSelection,
      week?.days,
      setStartSelectingPos,
      isSelectingReal,
    ]
  )

  // Bind all the key events
  useKeyPress(
    !hasModalOpened && canEdit
      ? ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Tab', 'Enter', 'NumpadEnter', 'Escape']
      : [],
    onExcelKeyPress
  )

  // Bind CTRL + Z
  useKeyPress(!hasModalOpened && canEdit ? [UNDO_KEY] : [], undo)

  // Set Start position
  useEffect(() => {
    if (isLeftClicking && !startSelectingPos && sessionHovered) {
      setStartSelectingPos(sessionHovered)
    }
  }, [isLeftClicking, startSelectingPos, sessionHovered, setStartSelectingPos])

  // Effectivley select sessions
  useEffect(() => {
    if (isSelecting && sessionHovered && startSelectingPos && week) {
      // select all session needed betweeen startPos and sessionHovered
      const x = [sessionHovered.x, startSelectingPos.x].sort()
      const y = [sessionHovered.y, startSelectingPos.y].sort()

      const toBeSelectedSessions = {}
      week.days
        .filter((d) => d.isReal === isSelectingReal)
        .forEach((d) => {
          d.intervals.forEach((i) => {
            i.sessions.forEach((s) => {
              if (isInRange(s, { xmin: x[0], xmax: x[1], ymin: y[0], ymax: y[1] })) {
                toBeSelectedSessions[s._id] = true
              }
            })
          })
        })

      updateSelectedSessions(toBeSelectedSessions)
    }
  }, [
    sessionHovered,
    isSelecting,
    startSelectingPos,
    week,
    updateSelectedSessions,
    isSelectingReal,
  ])

  return null
}
