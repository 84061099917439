import { useCallback, useEffect, useRef, useState } from 'react'
import { LEFT_CLICK, RIGHT_CLICK } from './constantes'

// Triggers the callback when there's a click outside of the ref parameter
export const useOutsideClick = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside()
      }
    }

    // Bind the event listener
    document.body.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.body.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

// Track the cliked buttons of the mouse
export const useIsMouseClicking = () => {
  const [btnClicking, setBtnClicking] = useState(null)

  const activateClick = (e) => {
    setBtnClicking(e.button)
  }
  const disabledClick = () => {
    setBtnClicking(null)
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', activateClick)
    document.addEventListener('mouseup', disabledClick)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', activateClick)
      document.removeEventListener('mouseup', disabledClick)
    }
  }, [])
  return {
    isRightClicking: btnClicking === RIGHT_CLICK,
    isLeftClicking: btnClicking === LEFT_CLICK,
  }
}

export const UNDO_KEY = 'CTRL+Z'
// Bind a keyPress event and execute the callback when the keyCodes has the one that has been pressed
export function useKeyPress(keyCodes = [], callback) {
  useEffect(() => {
    // Ne pas ajouter de listener si aucun keyCodes
    if (keyCodes.length === 0) {
      return
    }
    const handler = (event) => {
      const { key, code } = event
      if (keyCodes.includes(key) || keyCodes.includes(code)) {
        callback(event)
      }
      if (keyCodes.includes(UNDO_KEY) && event.ctrlKey && key === 'z') {
        callback(event)
      }
    }

    window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [keyCodes, callback])
}

export const useKonamiCode = (
  callback,
  sequence = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ]
) => {
  const buffer = useRef([])

  const keySequence = useCallback(
    (event) => {
      if (event.defaultPrevented) return

      if (event.key === sequence[buffer.current.length]) {
        buffer.current = [...buffer.current, event.key]
      } else {
        buffer.current = []
      }

      if (buffer.current.length === sequence.length) {
        const bufferString = buffer.current.toString()
        const sequenceString = sequence.toString()

        if (sequenceString === bufferString) {
          callback()
        }
      }
    },
    [callback, sequence]
  )

  useEffect(() => {
    document.addEventListener('keydown', keySequence)
    return () => document.removeEventListener('keydown', keySequence)
  }, [keySequence])
}

// Execute the handler when a certain scroll threshold is passed
export const useScrollThreshold = (threshold, handler) => {
  useEffect(() => {
    // Ne pas ajouter de listener si le seuil est 0
    if (threshold === 0) {
      return
    }

    const handleScroll = (event) => {
      // Détermine si le défilement est positif ou négatif
      const isPositiveScroll = event.deltaY > 0

      // Vérifie si le seuil de défilement est dépassé
      if (Math.abs(event.deltaY) > threshold) {
        handler(isPositiveScroll, event)
      }
    }

    // Ajoute un écouteur d'événement pour le défilement de la souris
    window.addEventListener('wheel', handleScroll, { passive: false })

    // Nettoyage de le listener lorsque le composant est démonté
    return () => {
      window.removeEventListener('wheel', handleScroll, { passive: false })
    }
  }, [threshold, handler])
}

// Unused ! : Track mouse Position
export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null })
  useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY })
    }
    window.addEventListener('mousemove', updateMousePosition)
    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
    }
  }, [])
  return mousePosition
}

export const useIsDocumentHidden = () => {
  const [isDocHidden, setIsDocHidden] = useState(document.hidden)

  useEffect(() => {
    const updateDocVisibility = () => {
      setIsDocHidden(document.hidden)
    }
    window.addEventListener('visibilitychange', updateDocVisibility)
    return () => {
      window.removeEventListener('visibilitychange', updateDocVisibility)
    }
  }, [])
  return isDocHidden
}

const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies)
}

export const useDebounce = (value, delay = 400) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value only after delay
      const timeoutId = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      return () => {
        clearTimeout(timeoutId)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}
