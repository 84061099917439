import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useAdditionalTimeStore = create(
  immer((set) => ({
    additionalTimeUser: null,
    setAdditionalTimeUser: (nextUser) =>
      set((state) => {
        state.additionalTimeUser = nextUser
      }),
  }))
)
