import localizedDayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/fr'

// allow dayjs to parse custom date formats
localizedDayjs.extend(customParseFormat)
localizedDayjs.extend(utc)
localizedDayjs.extend(timezone)
// localizedDayjs.tz.setDefault('Europe/Paris')
localizedDayjs.locale('fr')

export default localizedDayjs
