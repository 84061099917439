import axios from 'axios'
import { toast } from 'react-toastify'
import { routerObject } from '../router/Router'
import { ROUTE_SIGN_OUT, ROUTE_WEEK_VIEW } from '../router/Routes'
import { API_URL, TIMEOUT_AXIOS_CALLS } from '../utils/constantes'
import {
  ENDPOINT_LOGIN,
  ENDPOINT_REFRESH_TOKENS,
  ENDPOINT_ROOMS,
  ENDPOINT_USERS,
} from './Endpoints'
import { refreshTokens } from './TokenRefresh'
import { getAccessToken, isTokenMidLifetimePassed, saveTokens } from './TokenService'

const getTime = (withSeconds) => {
  return new Date().toTimeString().slice(0, withSeconds ? 8 : 5)
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT_AXIOS_CALLS,
})

const shouldAddAuthToken = (endPoint) => {
  return (
    endPoint !== ENDPOINT_REFRESH_TOKENS &&
    endPoint !== ENDPOINT_LOGIN &&
    endPoint !== ENDPOINT_ROOMS &&
    endPoint !== ENDPOINT_USERS
  )
}

// Request interceptor (Adds token if needed)
axiosInstance.interceptors.request.use(
  async (config) => {
    const endPoint = config?.url
    console.log(`[${getTime(true)}][<${config?.method}>${endPoint}] CALLING`)

    // 1 - on check si la midlife du token n'est pas passée ou même si le token est expiré
    if (shouldAddAuthToken(endPoint)) {
      const isMidlifePassed = isTokenMidLifetimePassed()
      if (isMidlifePassed) {
        // Si en effet c'est expiré ( / ou midlifé ) on test de refresh le token
        const r = await refreshTokens()
        if (r?.status === 200) {
          saveTokens(r?.data)
        } else {
          // Ici on throw pour ne pas déclencher l'appel demandé : de toute façon pas de token correct
          throw new axios.Cancel('Error when refreshing token : canceling')
        }
      }

      const token = getAccessToken()
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  // status code 2xx
  (response) => {
    // const endPoint = response?.config?.url;
    // console.log(`[${getTime(true)}][${endPoint}] RESPONSE !`);
    return response
  },
  // status code NOT 2xx
  async (error) => {
    const errorStatus = error?.response?.status || 'unknow_status'
    let message = error?.response?.data?.message || error?.response?.statusText || 'NO_MESSAGE'
    const endPoint = error?.config?.url
    console.log(`[${getTime(true)}][${endPoint}] ERROR ${errorStatus}`)

    // Gestion d'une déconnection pour tous les status 401
    if (errorStatus === 401) {
      routerObject.navigate(ROUTE_SIGN_OUT)
    }
    // UnAuthorized --> Redirect WEEK_VIEW
    if (errorStatus === 403) {
      routerObject.navigate(ROUTE_WEEK_VIEW, { replace: true })
    }

    switch (error?.code) {
      case 'ECONNABORTED':
        // Gestion du timeout d'axios ( TIMEOUT_AXIOS_CALLS secondes )
        console.log(`Erreur : Timeout du serveur (${TIMEOUT_AXIOS_CALLS / 1000}s)`)
        break
      case 'ERR_NETWORK':
        message = 'La connection au serveur semble impossible'
        break
      case 'ERR_CANCELED':
        console.log(`Erreur : Request canceled : refresh token failed`)
        // break interceptor chain ( Prevent calling on a token refresh failed )
        return new Promise(() => {})
      default:
        break
    }
    if (message !== 'NO_MESSAGE') {
      if (error?.response?.data?.severity === 'warning') {
        toast.warn(message, { toastId: message })
      } else if (error?.response?.data?.severity === 'info') {
        toast.info(message, { toastId: message })
      } else if (error?.response?.data?.severity === 'success') {
        toast.success(message, { toastId: message })
      } else {
        toast.error(message, { toastId: message })
      }
    } else {
      toast.error(`Erreur ${errorStatus}`)
    }
    console.error(error, message)
    return { ...error, ...error?.response }
  }
)

export default axiosInstance
