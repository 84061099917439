import { CssBaseline, useMediaQuery } from '@mui/material'
import {
  createTheme,
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles'
import { createContext, useContext, useMemo, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { KEY_PREFERENCE_DARK_THEME, PRIMARY, SECONDARY } from '../utils/constantes'

const darkPalette = {
  mode: 'dark',
  primary: {
    main: PRIMARY,
  },
  secondary: {
    main: SECONDARY,
  },
  background: {
    default: '#1f1f1f',
  },
  altered: {
    100: '#2b2b2b',
    200: '#333333',
    300: '#3b3b3b',
    400: '#444444',
    500: '#4c4c4c',
    600: '#555555',
    700: '#5d5d5d',
    800: '#666666',
    900: '#6e6e6e',
  },
  notavailable: {
    default: '#ffe699',
  },
  isclosing: {
    default: '#dbd894',
  },
}

const lightPalette = {
  mode: 'light',
  primary: {
    main: PRIMARY,
  },
  secondary: {
    main: SECONDARY,
  },
  background: {
    default: '#ebebeb',
  },
  altered: {
    100: '#d9d9d9',
    200: '#bebebe',
    300: '#a6a6a6',
    400: '#999999',
    500: '#8c8c8c',
    600: '#808080',
    700: '#737373',
    800: '#666666',
    900: '#595959',
  },
  notavailable: {
    default: '#ffe699',
  },
  isclosing: {
    default: '#dbd894',
  },
}

const ThemeContext = createContext({})
export const useThemeContext = () => useContext(ThemeContext)

const ThemeProvider = ({ children }) => {
  // Get as initial value the computer (browser ?) theme preference
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  // Save it locally
  const localPreferDark = localStorage.getItem(KEY_PREFERENCE_DARK_THEME)

  const [preferDark, setPreferDark] = useState(
    localPreferDark !== null ? localPreferDark === 'true' : prefersDarkMode
  )

  const theme = useMemo(
    () =>
      extendTheme(
        createTheme({
          palette: preferDark ? darkPalette : lightPalette,
          themeName: 'MyTheme',
        })
      ),
    [preferDark]
  )

  // Toggle the theme and store the preference
  const toggleDarkTheme = () => {
    setPreferDark(!preferDark)
    localStorage.setItem(KEY_PREFERENCE_DARK_THEME, !preferDark)
  }

  return (
    <ThemeContext.Provider value={{ toggleDarkTheme, preferDark, setPreferDark }}>
      {/* Allow to access the theme variable into CSS */}
      <CssVarsProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </CssVarsProvider>

      {/* Default values for all the toasts */}
      <ToastContainer
        limit={4}
        theme={preferDark ? 'dark' : 'light'}
        pauseOnFocusLoss
        pauseOnHover
        autoClose={3500}
      />
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
