import { OTHER_HEADERS } from './constantes'
import dayjs from './dayjsHelper'

export const getMondayOfSameWeek = (date) => {
  return dayjs(date).startOf('week')
}

export const getWeekDates = (monday) => {
  const dates = []
  for (let i = 0; i < 7; i++) {
    const currentDate = monday.add(i, 'day')
    dates.push(currentDate)
  }
  return dates
}

/**
 *
 * @param {*} session
 * @param {*} coordinates {xmin, xmax, ymin, ymax}
 * @returns true | false
 */
export const isInRange = (session, { xmin, xmax, ymin, ymax }) => {
  const { x, y } = session
  const isXOk = Math.round(x) >= Math.round(xmin) && Math.round(x) <= Math.round(xmax)
  const isYOk = Math.round(y) >= Math.round(ymin) && Math.round(y) <= Math.round(ymax)

  return isXOk && isYOk
}

export const isEmpty = (obj) => Object.keys(obj).length === 0

export const preventDefault = (e) => {
  e.preventDefault()
  return false
}

export const sortUsersWrapper = (usersWeek = []) => {
  return usersWeek.toSorted((uw1, uw2) => {
    if (uw1?.user?.contract <= 0 && uw2?.user?.contract > 0) {
      return -1
    }
    if (uw2?.user?.contract <= 0 && uw1?.user?.contract > 0) {
      return 1
    }
    return uw1?.user?.position - uw2?.user.position
  })
}
export const sortUsers = (users = []) => {
  return users.toSorted((u1, u2) => {
    if (u1?.contract <= 0 && u2?.contract > 0) {
      return -1
    }
    if (u2?.contract <= 0 && u1?.contract > 0) {
      return 1
    }
    return u1?.position - u2?.position
  })
}
export const sortSessions = (sessions = []) => {
  return sessions.toSorted((s1, s2) => {
    if (s1?.room?.id && s2?.room?.id) {
      return s1?.room?.id - s2?.room?.id
    }
    if (s1?.room?.id && !s2?.room?.id) {
      return -1
    }
    if (!s1?.room?.id && s2?.room?.id) {
      return 1
    }
    // Sort by categorie:
    const o1 = OTHER_HEADERS.find((h) => h.category === s1.category)
    const o2 = OTHER_HEADERS.find((h) => h.category === s2.category)
    return o1?.order - o2?.order
  })
}

export const sortIntervals = (intervals) => {
  return intervals.toSorted((a, b) => new Date(a.start) - new Date(b.start))
}

export const capitalize = (str) => {
  if (str?.length > 0) {
    return str.slice(0, 1).toUpperCase() + str.slice(1, str.length)
  } else {
    return ''
  }
}

export const getClipboardText = async () => {
  const clipBoardItems = await navigator.clipboard.read()
  const firstClipBoard = clipBoardItems[0]
  if (firstClipBoard && firstClipBoard.types[0]) {
    const blob = await firstClipBoard.getType(firstClipBoard.types[0])
    if (blob) {
      return await blob.text()
    }
  }
  return null
}

export const deepCloneButKeepId = (toClone, actual) => {
  const next = Array.isArray(toClone) ? [] : {}
  for (const key in toClone) {
    if (typeof toClone[key] === 'object' && toClone[key] !== null) {
      try {
        next[key] = deepCloneButKeepId(toClone[key], actual[key])
      } catch (e) {
        console.error('deepCloneButKeepId error :', e)
      }
    } else if (key === '_id' && !!actual?.[key]) {
      // key === _id --> On garde celui de l'ancien obj
      next[key] = actual[key]
    } else if (key === 'timeStamp') {
      // key === 'timeStamp' --> Cas particulier d'objet sans vrai id (ex: commentaries)
      // On lui créer son propre timestamp :
      next[key] = new Date().getTime().toString()
    } else {
      next[key] = toClone?.[key]
    }
  }
  return next
}

export const copyArrayWithoutIds = (arr) => {
  if (!Array.isArray(arr)) {
    console.error('Structure is not an array : ', arr)
    return
  }
  return arr.map((elem) => {
    // eslint-disable-next-line no-unused-vars
    const { _id, ...rest } = elem
    return rest
  })
}

export const isMobile = () => {
  const a = navigator.userAgent || navigator.vendor || window.opera
  // prettier-ignore
  // eslint-disable-next-line no-useless-escape
  if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) ||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
    return true
  }
  return false
}

export const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent || window.navigator?.userAgentData?.platform) &&
    !window.MSStream
  )
}

// Set the value of the text into the Clipboard of the user
export const setClipboard = async (text) => {
  const type = 'text/plain'
  const blob = new Blob([text], { type })
  const data = [new ClipboardItem({ [type]: blob })]
  await navigator.clipboard.write(data)
}

export const sanitize = (str) =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '')

export const decimalHourToTime = (decimal, separator = 'h') => {
  const decimalFloat = Math.round(parseFloat(decimal) * 100) / 100
  const hours = Math.floor(decimalFloat)
  let minutes = Math.round((decimal - hours) * 60)
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  return `${hours}${separator}${minutes}`
}
