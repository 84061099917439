import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useDayCommentStore = create(
  immer((set) => ({
    dayCommentId: null,
    setDayCommentId: (nextId) =>
      set((state) => {
        state.dayCommentId = nextId
      }),
  }))
)
