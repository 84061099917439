import axiosInstance from './AxiosWrapper'
import {
  ENDPOINT_IMPORT_NEW_USERS,
  ENDPOINT_IMPORT_STOCKMANAGER,
  ENDPOINT_INDISPOS,
  ENDPOINT_WEEK,
  ENDPOINT_WEEK_FOR_MG,
  ENDPOINT_WEEK_TOGGLE_EDITABLE,
  ENDPOINT_WEEK_TOGGLE_CAN_ASK_INDISPO,
  ENDPOINT_WEEK_TOGGLE_VISIBLE_MG,
} from './Endpoints'

export const getWeekService = async (date) => {
  return await axiosInstance.get(ENDPOINT_WEEK, { params: { date } })
}
export const saveWeekService = async (weekId, newWeek) => {
  return await axiosInstance.post(ENDPOINT_WEEK, { id: weekId, newWeek })
}
export const saveIndispoService = async (weekId, newWeek) => {
  return await axiosInstance.post(ENDPOINT_INDISPOS, { id: weekId, newWeek })
}
export const toggleIsWeekEditableService = async (weekId) => {
  return await axiosInstance.post(ENDPOINT_WEEK_TOGGLE_EDITABLE, { id: weekId })
}
export const toggleCanAskIndispoService = async (weekId) => {
  return await axiosInstance.post(ENDPOINT_WEEK_TOGGLE_CAN_ASK_INDISPO, { id: weekId })
}
export const toggleVisibleMGService = async (weekId) => {
  return await axiosInstance.post(ENDPOINT_WEEK_TOGGLE_VISIBLE_MG, { id: weekId })
}
export const getWeekForMGService = async (date) => {
  return await axiosInstance.get(ENDPOINT_WEEK_FOR_MG, { params: { date } })
}
export const importBDDService = async (date) => {
  return await axiosInstance.get(ENDPOINT_IMPORT_STOCKMANAGER, { params: { date, nbWeeks: 1 } })
}
export const importNewUsersService = async (weekId) => {
  return await axiosInstance.get(ENDPOINT_IMPORT_NEW_USERS, { params: { weekId } })
}
