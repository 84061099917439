import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'
import { populateWeek, unpopulateWeek } from '../pages/generator/generatorUtils/populates.js'
import { ROUTE_WEEK_VIEW } from '../router/Routes.jsx'
import { toggleRealEnabledService } from '../services/DaysService.js'
import {
  getWeekForMGService,
  getWeekService,
  importNewUsersService,
  saveIndispoService,
  saveWeekService,
  toggleCanAskIndispoService,
  toggleIsWeekEditableService,
  toggleVisibleMGService,
} from '../services/WeeksService'
import { SESSION_DEFAULT_URATION } from '../utils/constantes.js'
import dayjs from '../utils/dayjsHelper.js'
import { deepCloneButKeepId } from '../utils/utils'
import { useDateStore } from './DateStore.jsx'
import { useRoomsStore } from './RoomsStore'
import { useSelectionStore } from './SelectionStore'
import { useUsersStore } from './UsersStore'
import { useDebounce } from '../utils/customHooks.js'

const MAX_UNDO_SIZE = 40

const recordForUndo = (state, weekToSave) => {
  state.weekSaves.push(weekToSave)
  state.weekSaves.length > MAX_UNDO_SIZE && state.weekSaves.shift()
}

export const useWeekStore = create(
  immer((set, get) => ({
    isWeekLoading: false,
    isWeekSaving: false,
    isDirty: false,
    week: null,
    weekSaves: [],

    // Fetch the week of the current state.date
    fetchWeek: async () => {
      const users = useUsersStore.getState().users
      const rooms = useRoomsStore.getState().rooms
      if (Object.keys(users).length === 0) return
      if (Object.keys(rooms).length === 0) return
      const date = useDateStore.getState().date
      const isWeekLoading = get().isWeekLoading
      if (isWeekLoading) return
      set({ isWeekLoading: true })
      const result = await getWeekService(date.toISOString())
      set({ isWeekLoading: false })
      if (result?.status === 200) {
        const nextWeek = result?.data
        if (nextWeek) {
          populateWeek(nextWeek, users, rooms)
          set({ week: nextWeek, isDirty: false, weekSaves: [] })
        }
      } else {
        set({ week: null })
      }
    },

    // Fetch the week for the MG View
    fetchWeekForMG: async () => {
      const users = useUsersStore.getState().users
      const rooms = useRoomsStore.getState().rooms
      if (Object.keys(users).length === 0) return
      if (Object.keys(rooms).length === 0) return
      const date = useDateStore.getState().date
      const isWeekLoading = get().isWeekLoading
      if (isWeekLoading) return
      set({ isWeekLoading: true })
      const result = await getWeekForMGService(date.toISOString())
      set({ isWeekLoading: false })
      if (result?.status === 200) {
        const nextWeek = result?.data
        if (nextWeek) {
          populateWeek(nextWeek, users, rooms)
          set({ week: nextWeek, weekSaves: [] })
        }
      } else {
        set({ week: null })
      }
    },

    importNewUsers: async () => {
      const fetchWeek = get().fetchWeek
      set({ isWeekSaving: true })
      const result = await importNewUsersService(get().week._id)
      set({ isWeekSaving: false })
      if (result?.status === 200) {
        toast.success(result?.data?.message)
        fetchWeek()
      }
    },

    // Save the week
    saveWeek: async () => {
      const week = get().week
      const nextWeek = JSON.parse(JSON.stringify(week))
      const users = useUsersStore.getState().users
      const rooms = useRoomsStore.getState().rooms
      unpopulateWeek(nextWeek)
      set({ isWeekSaving: true })
      const result = await saveWeekService(week._id, nextWeek)
      set({ isWeekSaving: false })
      if (result?.status === 200) {
        toast.success(result?.data?.message)
        const updatedWeek = result?.data?.updatedWeek
        populateWeek(updatedWeek, users, rooms)
        set({ week: updatedWeek, isDirty: false, weekSaves: [] })
      }
    },

    // Save Indispos
    saveIndispos: async () => {
      const week = get().week
      const nextWeek = JSON.parse(JSON.stringify(week))
      unpopulateWeek(nextWeek)
      set({ isWeekSaving: true })
      const result = await saveIndispoService(week._id, nextWeek)
      set({ isWeekSaving: false })
      if (result?.status === 200) {
        toast.success(result?.data?.message)
        set({ isDirty: false })
      }
    },

    // Toggle the editable field of the week
    toggleIsWeekEditable: async () => {
      const week = get().week
      set({ isWeekSaving: true })
      const result = await toggleIsWeekEditableService(week._id)
      set({ isWeekSaving: false })
      if (result?.status === 200) {
        toast.success(result?.data?.message)
        const updatedWeek = result?.data?.updatedWeek
        if (updatedWeek) {
          const users = useUsersStore.getState().users
          const rooms = useRoomsStore.getState().rooms
          populateWeek(updatedWeek, users, rooms)
          set({ week: updatedWeek })
        }
      }
    },

    // Toggle the editable field of the week
    toggleCanAskIndispo: async () => {
      const week = get().week
      set({ isWeekSaving: true })
      const result = await toggleCanAskIndispoService(week._id)
      set({ isWeekSaving: false })
      if (result?.status === 200) {
        toast.success(result?.data?.message)
        const updatedWeek = result?.data?.updatedWeek
        if (updatedWeek) {
          const users = useUsersStore.getState().users
          const rooms = useRoomsStore.getState().rooms
          populateWeek(updatedWeek, users, rooms)
          set({ week: updatedWeek })
        }
      }
    },

    // Toggle the isVisibleByMG field of the week
    toggleVisibleMG: async () => {
      const week = get().week
      const setWeekValue = get().setWeekValue
      const result = await toggleVisibleMGService(week._id)
      if (result?.status === 200) {
        toast.success(result?.data?.message)
        setWeekValue('isVisibleByMG', result?.data?.nextIsVisible)
      }
    },

    // Toggle the realEnabled of one day
    toggleRealEnabled: async (day) => {
      const updateDayValue = get().updateDayValue
      const result = await toggleRealEnabledService(day._id)
      if (result?.status === 200) {
        toast.success(result?.data?.message)
        updateDayValue(result?.data?.dayId, 'realEnabled', result?.data?.nextRealEnabled)
      }
    },
    setWeek: (nextWeek) =>
      set((state) => {
        state.week = nextWeek
      }),
    setWeekValue: (key, value) =>
      set((state) => {
        state.week[key] = value
      }),
    updateDayValue: (dayId, key, value) =>
      set((state) => {
        const dayToUpdate = state.week.days.find((d) => d._id === dayId)
        if (dayToUpdate) {
          dayToUpdate[key] = value
          state.isDirty = true
        }
      }),
    updateUserWeekValue: (userWeekId, key, value) =>
      set((state) => {
        const userWeekToUpdate = state.week.users_week.find((uw) => uw._id === userWeekId)
        if (userWeekToUpdate) {
          userWeekToUpdate[key] = value
          state.isDirty = true
        }
      }),
    updateUserIntervalValue: (userIntervalId, key, value) =>
      set((state) => {
        const userIntervalToUpdate = state.week.days
          .flatMap((day) => day.intervals)
          .flatMap((interval) => interval.users_interval)
          .find((ui) => ui._id === userIntervalId)
        if (userIntervalToUpdate) {
          if (userIntervalToUpdate[key] !== value) {
            recordForUndo(state, get().week)
            userIntervalToUpdate[key] = value
            state.isDirty = true
          }
        }
      }),
    updateSessionValue: (sessionId, key, value) =>
      set((state) => {
        const sessionToUpdate = state.week.days
          .flatMap((day) => day.intervals)
          .flatMap((interval) => interval.sessions)
          .find((session) => session._id === sessionId)
        if (sessionToUpdate) {
          if (sessionToUpdate[key] !== value) {
            recordForUndo(state, get().week)
            sessionToUpdate[key] = value
            state.isDirty = true
          }
        }
      }),
    refreshSession: (sessionId, nextSession) =>
      set((state) => {
        const sessionToUpdate = state.week?.days
          ?.flatMap((day) => day.intervals)
          ?.flatMap((interval) => interval.sessions)
          ?.find((session) => session._id === sessionId)
        if (sessionToUpdate) {
          Object.assign(sessionToUpdate, nextSession)
        }
      }),

    // Update all session in selection to the desired value
    updateAllSessionValueSelected: (key, value) =>
      set((state) => {
        recordForUndo(state, get().week)
        const selectedIds = Object.keys(useSelectionStore.getState().selectedSessions)
        const allSessionToUpdate = state.week.days
          .flatMap((day) => day.intervals)
          .flatMap((interval) => interval.sessions)
          .filter((session) => selectedIds.includes(session._id))
        allSessionToUpdate.forEach((s) => {
          s[key] = value
        })
        state.isDirty = true
      }),
    setEventSession: (sessionId, event) =>
      set((state) => {
        recordForUndo(state, get().week)
        const sessionToUpdate = state.week.days
          .flatMap((day) => day.intervals)
          .flatMap((interval) => interval.sessions)
          .find((session) => session._id === sessionId)
        if (sessionToUpdate) {
          let nextEvents = [...sessionToUpdate.events]
          if (!nextEvents.includes(event)) {
            nextEvents.push(event)
          }
          sessionToUpdate.events = nextEvents
          state.isDirty = true
        }
      }),

    // Reset all sessions field (mg, events, highlight,... )
    resetSession: (sessionId) =>
      set((state) => {
        const sessionToReset = state.week.days
          .flatMap((day) => day.intervals)
          .flatMap((interval) => interval.sessions)
          .find((session) => session._id === sessionId)
        if (sessionToReset) {
          recordForUndo(state, get().week)
          sessionToReset.mg = null
          sessionToReset.lock = false
          sessionToReset.events = []
          sessionToReset.highlight = false
          sessionToReset.duration = SESSION_DEFAULT_URATION
          sessionToReset.available = true
          state.isDirty = true
        }
      }),

    // Same as reset but for all selected sessions
    resetAllSelectedSession: () =>
      set((state) => {
        recordForUndo(state, get().week)
        const selectedIds = Object.keys(useSelectionStore.getState().selectedSessions)
        const allSessionToUpdate = state.week.days
          .flatMap((day) => day.intervals)
          .flatMap((interval) => interval.sessions)
          .filter((session) => selectedIds.includes(session._id))
        allSessionToUpdate.forEach((s) => {
          s.mg = null
          s.lock = false
          s.events = []
          s.highlight = false
          s.duration = SESSION_DEFAULT_URATION
          s.available = true
        })
        state.isDirty = true
      }),
    addAdditionalTimeForUser: (
      dayIndex,
      user,
      commentary = '',
      duration = 0,
      lastID,
      lastTimeStamp
    ) =>
      set((state) => {
        recordForUndo(state, get().week)
        const day = state.week.days
          .filter((d) => d.isReal)
          .find((d) => {
            const dayIndexLocal = dayjs(d.date).day() === 0 ? 6 : dayjs(d.date).day() - 1
            return dayIndexLocal === dayIndex
          })
        if (day) {
          const d = new Date()
          day.additionalTimes.push({
            _id: lastID,
            commentary,
            duration,
            user,
            dayIndex,
            timeStamp: lastTimeStamp || d.getTime().toString(),
          })
          state.isDirty = true
        }
      }),
    deleteAT: (searchedId) =>
      set((state) => {
        recordForUndo(state, get().week)
        state.week.days
          .filter((d) => d.isReal)
          .forEach((d) => {
            d.additionalTimes = d.additionalTimes.filter(
              (at) => at.timeStamp !== searchedId && at?._id !== searchedId
            )
          })
        state.isDirty = true
      }),
    editAT: (searchedId, key, value) =>
      set((state) => {
        recordForUndo(state, get().week)
        const toEdit = state.week.days
          .flatMap((d) => d.additionalTimes)
          .find((at) => at.timeStamp === searchedId || at?._id === searchedId)

        if (toEdit) {
          toEdit[key] = value
          state.isDirty = true
        }
      }),

    // When pasting some data it will fill up the sessions according to the startSelectingPosition
    onPasteWeekValues: (pastedText) =>
      set((state) => {
        recordForUndo(state, get().week)
        const users = useUsersStore.getState().users
        const { startSelectingPos, isSelectingReal } = useSelectionStore.getState()
        if (startSelectingPos) {
          const lines = pastedText.replace(/\r/g, '').split(`\n`)
          const values = []
          for (var i = 0; i < lines.length; i++) {
            var elements = lines[i].split('\t')
            values.push(elements)
          }
          const width = values[0].length
          const height = values.length
          const startX = startSelectingPos.x
          const startY = startSelectingPos.y
          state.week.days
            .filter((d) => d.isReal === isSelectingReal)
            .flatMap((day) => day.intervals)
            .flatMap((interval) => interval.sessions)
            .forEach((s) => {
              if (s.x >= startX && s.x < startX + width) {
                if (s.y >= startY && s.y < startY + height) {
                  const currentX = s.x - startX
                  const currentY = s.y - startY
                  const userToSet = values[currentY][currentX]
                  const foundUser = Object.values(users)
                    .filter((u) => u.state !== 0)
                    .find((u) => u.initials === userToSet)
                  if (foundUser) {
                    s.mg = foundUser?._id
                  } else {
                    s.mg = null
                  }
                }
              }
            })
          state.isDirty = true
        }
      }),
    addDayComment: (dayIndex, isReal, place_id) => {
      const d = new Date()
      const tempId = d.getTime().toString()
      set((state) => {
        recordForUndo(state, get().week)
        const day = state.week.days
          .filter((d) => d.isReal === isReal)
          .find((d) => {
            const dayIndexLocal = dayjs(d.date).day() === 0 ? 6 : dayjs(d.date).day() - 1
            return dayIndexLocal === dayIndex
          })
        day.commentaries.push({
          text: '',
          place_id,
          timeStamp: tempId,
        })
        state.isDirty = true
      })
      return tempId
    },
    deleteDayComment: (id, isReal) =>
      set((state) => {
        recordForUndo(state, get().week)
        state.week.days
          .filter((d) => d.isReal === isReal)
          .forEach((d) => {
            d.commentaries = d.commentaries.filter((c) => c.timeStamp !== id && c?._id !== id)
          })
        state.isDirty = true
      }),
    editDayComment: (id, key, value) =>
      set((state) => {
        recordForUndo(state, get().week)
        const toEdit = state.week.days
          .flatMap((d) => d.commentaries)
          .find((c) => c.timeStamp === id || c?._id === id)
        if (toEdit) {
          toEdit[key] = value
          state.isDirty = true
        }
      }),

    // Migrate only 1 day
    prevToRealOneDay: (dayDate) =>
      set((state) => {
        recordForUndo(state, get().week)
        const days = state.week.days.filter((d) => d.date === dayDate)
        const prev = days.filter((d) => !d.isReal)[0]
        const real = days.filter((d) => d.isReal)[0]
        real.commentaries = deepCloneButKeepId(prev.commentaries, real.commentaries)
        real.intervals = deepCloneButKeepId(prev.intervals, real.intervals)
        state.isDirty = true
      }),

    // Refresh local data of indispo for a user (after a chat indispo validation)
    updateIndispoOfUser: (userId, propal) =>
      set((state) => {
        state.week.days
          .filter((d) => !d.isReal)
          .forEach((day, dayIndex) => {
            day.intervals.forEach((interval, intervalIndex) => {
              const ui = interval.users_interval.find((ui) => ui?.user?._id.toString() === userId)
              if (ui) {
                ui.available = propal[dayIndex][intervalIndex]
              }
            })
          })
        state.week.users_week.forEach((uw) => {
          if (uw.user._id.toString() === userId) {
            uw.indispoChecked = true
          }
        })
      }),

    undo: () => {
      set((state) => {
        if (state.weekSaves.length > 0) {
          state.week = state.weekSaves.pop()
        } else {
          toast.info('Pas de sauvegarde précédente')
        }
      })
    },
  }))
)

export const WeekStoreWrapper = ({ children }) => {
  const { pathname } = useLocation()

  const { fetchWeek, fetchWeekForMG } = useWeekStore(
    useShallow((state) => ({
      fetchWeek: state.fetchWeek,
      fetchWeekForMG: state.fetchWeekForMG,
    }))
  )
  const { date } = useDateStore(
    useShallow((state) => ({
      date: state.date,
    }))
  )
  const users = useUsersStore(useShallow((state) => state.users))
  const rooms = useRoomsStore(useShallow((state) => state.rooms))

  const debouncedDate = useDebounce(date, 500)

  useEffect(() => {
    if (debouncedDate) {
      if (pathname === ROUTE_WEEK_VIEW) {
        fetchWeekForMG()
      } else {
        fetchWeek()
      }
    }
  }, [debouncedDate, fetchWeek, users, rooms, pathname, fetchWeekForMG])

  return children
}
