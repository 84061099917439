import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'
import { populateDay } from '../pages/generator/generatorUtils/populates.js'
import { getDayForMGService } from '../services/DaysService.js'
import { useDateStore } from './DateStore.jsx'
import { useRoomsStore } from './RoomsStore.jsx'
import { useSocketStore } from './SocketStore.jsx'
import { useUsersStore } from './UsersStore.jsx'

export const useDayStore = create(
  immer((set) => ({
    isDayLoading: true,
    day: null,

    // Fetch the day of the current state.date
    fetchDay: async () => {
      const users = useUsersStore.getState().users
      const rooms = useRoomsStore.getState().rooms
      if (Object.keys(users).length === 0) return
      if (Object.keys(rooms).length === 0) return
      const date = useDateStore.getState().date
      set({ isDayLoading: true })
      const result = await getDayForMGService(date.toISOString())
      set({ isDayLoading: false })
      if (result?.status === 200) {
        const nextDay = result?.data
        if (nextDay) {
          populateDay(nextDay, users, rooms)
          set({ day: nextDay })
        }
      } else {
        set({ day: null })
      }
    },
    updateSessionValue: (dayId, intervalId, sessionId, key, value) => {
      set((state) => {
        const sessionToUpdate = state.day.intervals
          .flatMap((interval) => interval.sessions)
          .find((session) => session._id === sessionId)
        if (sessionToUpdate && sessionToUpdate[key] !== value) {
          const socket = useSocketStore.getState().socket
          socket.emit(
            'dayView.updateSession',
            dayId,
            intervalId,
            sessionId,
            {
              ...sessionToUpdate,
              [key]: value,
            },
            (returnedData) => {
              if (returnedData.type === 'success') {
                toast.success(returnedData.message, { toastId: 'updateSession.sucess' })
              } else {
                toast.error(returnedData?.message || 'Unknown error', {
                  toastId: 'updateSession.error',
                })
              }
            }
          )
          sessionToUpdate[key] = value
        }
      })
    },
    updateGroupValue: (dayId, intervalId, groupId, key, value) =>
      set((state) => {
        const groupToUpdate = state.day.intervals
          .flatMap((interval) => interval.groups)
          .find((group) => group._id === groupId)
        if (groupToUpdate && groupToUpdate[key] !== value) {
          const socket = useSocketStore.getState().socket
          socket.emit(
            'dayView.updateGroup',
            dayId,
            intervalId,
            groupId,
            {
              ...groupToUpdate,
              [key]: value,
            },
            (returnedData) => {
              if (returnedData.type === 'success') {
                toast.success(returnedData.message, { toastId: 'updateGroup.sucess' })
              } else {
                toast.error(returnedData?.message || 'Unknown error', {
                  toastId: 'updateGroup.error',
                })
              }
            }
          )
          groupToUpdate[key] = value
        }
      }),
    refreshSession: (sessionId, nextSession) =>
      set((state) => {
        console.log('Refreshing session [', sessionId, ']')
        const sessionToUpdate = state.day.intervals
          .flatMap((interval) => interval.sessions)
          .find((session) => session._id === sessionId)
        if (sessionToUpdate) {
          Object.assign(sessionToUpdate, nextSession)
        }
      }),
    refreshGroup: (groupId, nextGroup) =>
      set((state) => {
        console.log('Refreshing group [', groupId, ']')
        const groupToUpdate = state.day.intervals
          .flatMap((interval) => interval.groups)
          .find((group) => group._id === groupId)
        if (groupToUpdate) {
          Object.assign(groupToUpdate, nextGroup)
        }
      }),
  }))
)

export const DayStoreWrapper = ({ children }) => {
  const { date } = useDateStore(
    useShallow((state) => ({
      date: state.date,
    }))
  )
  const { fetchDay } = useDayStore(
    useShallow((state) => ({
      fetchDay: state.fetchDay,
    }))
  )
  const users = useUsersStore(useShallow((state) => state.users))
  const rooms = useRoomsStore(useShallow((state) => state.rooms))

  useEffect(() => {
    if (date) {
      fetchDay()
    }
  }, [date, fetchDay, users, rooms])

  return children
}
