// USERS and ROOMS
export const ENDPOINT_USER = '/user'
export const ENDPOINT_USERS = '/users'
export const ENDPOINT_ROOMS = '/rooms'

// DATAS
export const ENDPOINT_WEEK = '/week'
export const ENDPOINT_INDISPOS = '/indispos'
export const ENDPOINT_WEEK_FOR_MG = '/weekForMG'
export const ENDPOINT_DAY_FOR_MG = '/dayForMG'
export const ENDPOINT_DAY_TOGGLE_REAL_ENABLED = '/day/toggleRealEnabled'
export const ENDPOINT_WEEK_TOGGLE_EDITABLE = '/week/toggleEditable'
export const ENDPOINT_WEEK_TOGGLE_CAN_ASK_INDISPO = '/week/toggleCanAskIndispo'
export const ENDPOINT_WEEK_TOGGLE_VISIBLE_MG = '/week/toggleVisibleMG'
export const ENDPOINT_IMPORT_STOCKMANAGER = '/importStockmanager'
export const ENDPOINT_MONTH_HOURS_COUNT = '/month/hoursCount'
export const ENDPOINT_COUNT_RESERVATION_RATE = '/countReservationRate'
export const ENDPOINT_IMPORT_NEW_USERS = '/importNewUsers'
export const ENDPOINT_CHATINDISPO_PROPAL_ANALYSE = '/chatIndispoPropalAnalyse'

// AUTHENTICATION
export const ENDPOINT_LOGIN = '/login'
export const ENDPOINT_REFRESH_TOKENS = '/token/refresh'

// LOGS
export const ENDPOINT_LOGS = '/logs'
export const ENDPOINT_LOG_DETAIL = '/logs-detail'
export const ENDPOINT_LOG_RESTORE = '/restore-week'

// Mails
export const ENDPOINT_MAIL_NOTIF = '/mail/notif'
