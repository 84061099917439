import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { useAuthentificationStore } from './AuthenticationStore'

export const useChatIndispoStore = create(
  immer((set) => ({
    isVisionRow: true,
    setIsVisionRow: (nextValue) => set({ isVisionRow: nextValue }),

    // for chatIndispo management
    selectedUser: null,
    setSelectedUser: (nextUser) => set({ selectedUser: nextUser }),

    allChatIndispos: {},
    resetAllChatIndispo: () => set({ allChatIndispos: {} }),
    updateCurrentMessage: (chatIndispoId, nextMessage) =>
      set((state) => {
        const current = state.allChatIndispos[chatIndispoId]
        if (!!current) {
          current.currentMessage = nextMessage
        }
      }),
    updateChatIndispo: (chatIndispoId, nextChatIndispo) =>
      set((state) => {
        let nextNotifs = 0
        let notifsMG = 0

        // init if not present
        if (!state.allChatIndispos[chatIndispoId]) {
          state.allChatIndispos[chatIndispoId] = {
            _id: nextChatIndispo._id,
            user: nextChatIndispo.user,
            week: nextChatIndispo.week,
            actualIndispos: nextChatIndispo.actualIndispos,
            mailedAt: [],
            lastCheckedAt: [],
            lastSeensAt: [],
            messages: [],
            propals: [],
            notifs: 0,
            notifsMG: 0,
          }
        }

        const current = state.allChatIndispos[chatIndispoId]

        if (nextChatIndispo.week) {
          current.week = { ...current.week, ...nextChatIndispo.week }
        }
        if (nextChatIndispo.messages) {
          current.messages = nextChatIndispo.messages
        }
        if (nextChatIndispo.propals) {
          current.propals = nextChatIndispo.propals
        }
        if (nextChatIndispo.lastSeensAt) {
          current.lastSeensAt = nextChatIndispo.lastSeensAt
        }
        if (nextChatIndispo.lastCheckedAt) {
          current.lastCheckedAt = nextChatIndispo.lastCheckedAt
        }
        if (nextChatIndispo.actualIndispos) {
          current.actualIndispos = nextChatIndispo.actualIndispos
        }
        if (nextChatIndispo.mailedAt) {
          current.mailedAt = nextChatIndispo.mailedAt
        }

        // Calculate notifs for RC ( Nombre de message depuis le dernier check de qui que ce soit )
        const lastLastCheckedAt = current.lastCheckedAt.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        )[0]
        if (lastLastCheckedAt) {
          const allMissedMessages = [...current.messages, ...current.propals].filter(
            (m) => new Date(m.createdAt) > new Date(lastLastCheckedAt.updatedAt)
          )
          // console.log('lastLastCheckedAt', lastLastCheckedAt.updatedAt)
          // console.log('allMissedMessages length = ', allMissedMessages.length)
          nextNotifs = allMissedMessages.length
        } else {
          // Conversation never seen : full notifs
          nextNotifs = current.messages.length + current.propals.length
        }

        // Calculate notifs for MG ( Nombre de message depuis le dernier lastSeen de MG )
        const me = useAuthentificationStore.getState().user
        const myLastSeenAt = current.lastSeensAt.find((ls) => ls.user === me._id)
        if (myLastSeenAt) {
          const allMissedMessages = [...current.messages, ...current.propals].filter(
            (m) => new Date(m.createdAt) > new Date(myLastSeenAt.updatedAt)
          )
          notifsMG = allMissedMessages.length
        } else {
          // Conversation never seen : full notifs
          notifsMG = current.messages.length + current.propals.length
        }

        current.notifs = nextNotifs
        current.notifsMG = notifsMG
      }),
  }))
)
