import { CircularProgress } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useShallow } from 'zustand/react/shallow'
import { useAuthentificationStore } from '../contexts/AuthenticationStore'
import { ROUTE_WEEK_VIEW } from './Routes'

const ProtectedRoute = ({ needAuth, perm = null, message, children }) => {
  const navigate = useNavigate()
  const { isLoading, hasPerm, user } = useAuthentificationStore(
    useShallow((state) => ({
      isLoading: state.isLoading,
      hasPerm: state.hasPerm,
      user: state.user,
    }))
  )
  const canAccess = useMemo(() => {
    if (isLoading) return false
    if (needAuth && !user) {
      toast.error('Connecte toi pour accéder à cette page !', { toastId: 'protected_route' })
      return false
    }
    if (!!perm && !hasPerm(perm)) return false
    return true
  }, [hasPerm, isLoading, needAuth, perm, user])

  useEffect(() => {
    if (!isLoading && !canAccess) {
      toast.error(message || "Tu n'es pas autorisé à consulter cette page", {
        toastId: 'protected_route',
      })
    }
  }, [canAccess, isLoading, message, navigate])

  if (isLoading) {
    return (
      <div className="loginProgress">
        <CircularProgress size={60} />
      </div>
    )
  }
  if (!canAccess) {
    return <Navigate to={ROUTE_WEEK_VIEW} replace />
  }
  return children ? children : <Outlet />
}

export default ProtectedRoute
