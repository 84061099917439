import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { getAllRooms } from '../services/RoomsService'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

export const useRoomsStore = create(
  immer((set, get) => ({
    rooms: {},
    isLoading: true,
    changeIsLoading: (nextIsLoading) => set({ isLoading: nextIsLoading }),
    fetchRooms: async () => {
      const rooms = get().rooms
      if (Object.keys(rooms).length === 0) {
        set({ isLoading: true })
        const result = await getAllRooms()
        set({ isLoading: false })
        if (result?.status === 200) {
          if (result?.status === 200) {
            const nextRooms = {}
            result?.data.forEach((r) => {
              nextRooms[r?._id] = r
            })
            set({ rooms: nextRooms })
          }
        }
      }
    },
  }))
)

export const RoomStoreWrapper = ({ children }) => {
  const fetchRooms = useRoomsStore(useShallow((state) => state.fetchRooms))

  // Trigger the fetch of the rooms
  useEffect(() => {
    fetchRooms()
  }, [fetchRooms])

  return children
}
