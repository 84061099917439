import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { getAllUsers } from '../services/UsersService'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

export const useUsersStore = create(
  immer((set, get) => ({
    users: {},
    isLoading: true,
    changeIsLoading: (nextIsLoading) => set({ isLoading: nextIsLoading }),
    fetchUsers: async () => {
      const users = get().users
      if (Object.keys(users).length === 0) {
        set({ isLoading: true })
        const result = await getAllUsers()
        set({ isLoading: false })
        if (result?.status === 200) {
          if (result?.status === 200) {
            const userObj = {}
            result?.data.forEach((u) => {
              userObj[u._id] = u
            })
            set({ users: userObj })
          }
        }
      }
    },
  }))
)

export const UsersStoreWrapper = ({ children }) => {
  const fetchUsers = useUsersStore(useShallow((state) => state.fetchUsers))

  // Trigger the fetch of the users
  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return children
}
