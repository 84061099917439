import { CircularProgress } from '@mui/material'
import { lazy, Suspense } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import NotFound404 from '../pages/NotFound404/NotFound404'
import { PERM_LOG, PERM_PLANNING_EDIT, PERM_PLANNING_INDISPO } from '../utils/permissions'
import Layout from './Layout'
import ProtectedRoute from './ProtectedRoute'
import {
  ROUTE_DAY_VIEW,
  ROUTE_DAY_VIEW_HEADLESS,
  ROUTE_GENERATEUR,
  ROUTE_INDISPO,
  ROUTE_INDISPO_ASK,
  ROUTE_LOGIN,
  ROUTE_LOGS,
  ROUTE_SIGN_OUT,
  ROUTE_WEEK_VIEW,
} from './Routes'
import SignOutRoute from './SignOutRoute'
import { DateStoreWrapper } from '../contexts/DateStore'
import { DayStoreWrapper } from '../contexts/DayStore'
import { RoomStoreWrapper } from '../contexts/RoomsStore'
import { UsersStoreWrapper } from '../contexts/UsersStore'
import { WeekStoreWrapper } from '../contexts/WeekStore'

const Generator = lazy(() => import('../pages/generator/Generator'))
const LogsRenderer = lazy(() => import('../pages/logs/LogsRenderer'))
const LogInPage = lazy(() => import('../pages/login/LoginPage'))
const IndispoAsk = lazy(() => import('../pages/indispoAsk/IndispoAsk'))
const Indispo = lazy(() => import('../pages/indispo/Indispo'))
const WeekView = lazy(() => import('../pages/weekView/WeekView'))
const DayView = lazy(() => import('../pages/dayView/DayView'))

export const routerObject = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<LogInPage />} />
      <Route path={ROUTE_LOGIN} element={<LogInPage />} />
      <Route path={ROUTE_SIGN_OUT} element={<SignOutRoute />} />
      <Route
        path={ROUTE_DAY_VIEW_HEADLESS}
        element={
          <DateStoreWrapper>
            <RoomStoreWrapper>
              <UsersStoreWrapper>
                <DayStoreWrapper>
                  <DayView />
                </DayStoreWrapper>
              </UsersStoreWrapper>
            </RoomStoreWrapper>
          </DateStoreWrapper>
        }
      />
      {/**
       *
       *  AUTHENTIFICATION NEEDED
       *
       * */}
      <Route element={<ProtectedRoute needAuth />}>
        <Route
          path={ROUTE_WEEK_VIEW}
          element={
            <DateStoreWrapper>
              <RoomStoreWrapper>
                <UsersStoreWrapper>
                  <WeekStoreWrapper>
                    <WeekView />
                  </WeekStoreWrapper>
                </UsersStoreWrapper>
              </RoomStoreWrapper>
            </DateStoreWrapper>
          }
        />
      </Route>

      <Route element={<ProtectedRoute needAuth />}>
        <Route
          path={ROUTE_DAY_VIEW}
          element={
            <DateStoreWrapper>
              <RoomStoreWrapper>
                <UsersStoreWrapper>
                  <DayStoreWrapper>
                    <DayView />
                  </DayStoreWrapper>
                </UsersStoreWrapper>
              </RoomStoreWrapper>
            </DateStoreWrapper>
          }
        />
      </Route>

      <Route element={<ProtectedRoute needAuth perm={PERM_PLANNING_EDIT} />}>
        <Route
          path={ROUTE_GENERATEUR}
          element={
            <DateStoreWrapper>
              <RoomStoreWrapper>
                <UsersStoreWrapper>
                  <WeekStoreWrapper>
                    <Generator />
                  </WeekStoreWrapper>
                </UsersStoreWrapper>
              </RoomStoreWrapper>
            </DateStoreWrapper>
          }
        />
      </Route>
      <Route element={<ProtectedRoute needAuth perm={PERM_PLANNING_INDISPO} />}>
        <Route
          path={ROUTE_INDISPO}
          element={
            <DateStoreWrapper>
              <RoomStoreWrapper>
                <UsersStoreWrapper>
                  <WeekStoreWrapper>
                    <Indispo />
                  </WeekStoreWrapper>
                </UsersStoreWrapper>
              </RoomStoreWrapper>
            </DateStoreWrapper>
          }
        />
      </Route>

      <Route element={<ProtectedRoute needAuth />}>
        <Route
          path={ROUTE_INDISPO_ASK}
          element={
            <DateStoreWrapper>
              <RoomStoreWrapper>
                <UsersStoreWrapper>
                  <IndispoAsk />
                </UsersStoreWrapper>
              </RoomStoreWrapper>
            </DateStoreWrapper>
          }
        />
      </Route>

      <Route element={<ProtectedRoute needAuth perm={PERM_LOG} />}>
        <Route
          path={ROUTE_LOGS}
          element={
            <RoomStoreWrapper>
              <UsersStoreWrapper>
                <LogsRenderer />
              </UsersStoreWrapper>
            </RoomStoreWrapper>
          }
        />
      </Route>

      <Route path="*" element={<NotFound404 />} />
    </Route>
  )
)

const Router = () => {
  return (
    <Suspense
      fallback={
        <div className="centeredLoader">
          <CircularProgress size={100} />
        </div>
      }
    >
      <RouterProvider router={routerObject} />
    </Suspense>
  )
}

export default Router
