import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ReactDOM from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css'
import ThemeProvider from './contexts/ThemeProvider.jsx'
import './main.css'
import Router from './router/Router.jsx'
import { isProduction } from './utils/constantes.js'
import SocketInitiator from './SocketInitiator.jsx'

if (!isProduction) {
  // Indicate dev environment
  document.title = 'DEV - Planning V3 - DEV'
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
    <ThemeProvider>
      <Router />
      <SocketInitiator />
    </ThemeProvider>
  </LocalizationProvider>
)
