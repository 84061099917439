import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import './Layout.css'
import AppBar from './AppBar'
import { useAuthentificationStore } from '../contexts/AuthenticationStore'
import { useShallow } from 'zustand/react/shallow'
import { ROUTE_DAY_VIEW_HEADLESS, ROUTE_LOGIN, ROUTE_SIGN_OUT } from './Routes'
import { useEffect } from 'react'
import { KEY_USER_ID } from '../utils/constantes'
import { getUser } from '../services/AuthentificationService'
import { isIOS } from '../utils/utils'

const Layout = () => {
  const { user, setUser, signOut, setIsLoading, isLoading } = useAuthentificationStore(
    useShallow((state) => ({
      user: state.user,
      setUser: state.setUser,
      signOut: state.signOut,
      hasPerm: state.hasPerm,
      isLoading: state.isLoading,
      setIsLoading: state.setIsLoading,
    }))
  )
  const iOS = isIOS()
  const location = useLocation()
  const navigate = useNavigate()
  const pathTab = location?.pathname?.split('/')
  const hasAppBar =
    user && !pathTab[pathTab.length - 1].startsWith(ROUTE_DAY_VIEW_HEADLESS.slice(1))

  useEffect(() => {
    const getCurrentUser = async () => {
      if (
        !user &&
        location.pathname !== '/' &&
        location.pathname !== ROUTE_LOGIN &&
        location.pathname !== ROUTE_DAY_VIEW_HEADLESS &&
        location.pathname !== ROUTE_SIGN_OUT
      ) {
        let shouldSignOut = true
        const userId = localStorage.getItem(KEY_USER_ID)
        if (!!userId) {
          setIsLoading(true)
          const result = await getUser(userId)
          if (result?.status === 200) {
            shouldSignOut = false
            setUser(result?.data)
          }
        }
        if (shouldSignOut) {
          signOut()
          navigate('/', { state: location })
        }
      }
      setIsLoading(false)
    }
    getCurrentUser()
  }, [location, navigate, setUser, signOut, user, setIsLoading, isLoading])

  return (
    <div className={`app ${iOS ? 'isOShit' : ''}`}>
      <div className="content custom-scrollbar">
        {hasAppBar && <AppBar />}
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
